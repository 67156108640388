import {useState} from "react";

export default function useInitTerm() {
    const [termPageName, setTermPageName] = useState(null);
    const [modalIsActiveTerm, setModalIsActiveTerm] = useState(false);

    const openTermModal = (pageName) => {
        setModalIsActiveTerm(true);
        setTermPageName(pageName);
    };

    const closeTermModal = () => {
        setModalIsActiveTerm(false);
        setTermPageName(null);
    };

    return {
        termPageName,
        openTermModal,
        closeTermModal,
        modalIsActiveTerm
    };
}