import React from 'react';
import ComponentLnd16v2 from '../../components/lnd16v2';
import img from "../../img/lnd20p1";

export default () => {
    return (
        <ComponentLnd16v2
            mainTitle={'Get acquainted with Angelika asap!'}
            image={img.images['image1.jpg']}
            backgroundImage={img.images['bg.jpg']}
            title={'She is waiting for you on our service!'}
            description={'Register right now to continue chatting with a girl'}
            btnTitle={'OK'}
            subTitle={'FREE REGISTRATION'}
            subDescription={'no credit card required'}
        />
    );
};