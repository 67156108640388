import React, {useState, useRef} from 'react';
import useOutsideClicker from "../../../../hooks/useOutsideClicker";
import img from '../../../../img/lnd16';
import styles from "../../../../styles/lnd16v2/index.module.scss";

export default ({formData, defaultName, arrayFields, handleClick, index = null, setInputValid}) => {
    const [openBox, setOpenBox] = useState(false);
    const selectRef = useRef(null);

    useOutsideClicker(selectRef, setOpenBox);

    return (
        arrayFields && arrayFields.length > 0 ?
            <div className={`${styles.select_age_item} ${openBox ? styles.open : ''}`} ref={selectRef}>
                <div className={styles.select_age_head}
                     onClick={() => setOpenBox(!openBox)}>
                    <span>{+formData > 0 ? (index ? arrayFields[formData - 1] : formData) : defaultName}</span>
                    <img src={img.images['down_arrow.svg']} alt=""/>
                </div>
                <div className={styles.select_age_list_wrap}>
                    <div className={styles.select_age_list}>
                        {arrayFields?.map((item, key) => (
                            <div className={styles.select_age_list_item} key={`${key}_${item}`}>
                                <input id={`age_${index}_${key}`}
                                       type="radio"
                                       name={`age_${index}`}
                                />
                                <label htmlFor={`age_${index}_${key}`}
                                       onClick={() => {
                                           handleClick(key + 1, index);
                                           setOpenBox(false);
                                           setInputValid(true);
                                       }}>
                                    {item}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div> : null
    )
}