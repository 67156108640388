import React from "react";
import TabAge from '../../TabAge';
import TabName from '../../TabName';
import TabEmail from '../../TabEmail';
import TabGender from '../../TabGender';
import TabRelationship from '../../TabRelationship'
import TabRelationship2 from '../../TabRelationship2'
import TabPreferences from '../../TabPreferences'
import TabPreferences2 from '../../TabPreferences2'
import TabPassword from '../../TabPassword';
import TabSoulMates from '../../TabSoulMates';
import TabMetOnline from '../../TabMetOnline';
import LoaderModal from '../../../common/Modals/LoaderModal';
import useInitBlockForm from '../../../../hooks/lnd16/useInitBlockForm';

import styles from '../../../../styles/lnd16v2/index.module.scss';

export default (props) => {
    const initStateFormData = {
        gender: '',
        user_real_name: '',
        age: '',
        password: '',
        email: '',
        dd: 0,
        mm: 0,
        yyyy: 0
    };

    const {
        active,
        doSetActive,
        saveHistory,
        formData,
        setFormData,
        onSubmit,
        savePreferBodyType,
        savePreferAge,
        setFormDataByMDY,
        modalIsActiveLoader
    } = useInitBlockForm({initStateFormData, ...props});

    return (
        <>
            <div className={`${styles['bl-tabs']} bl-tabs`}>
                <div className={`${styles['tabs-info']} tabs-info`} id="formTabs">
                    <TabGender
                        active={active === 1}
                        setActive={() => doSetActive(2)}
                        saveHistory={saveHistory}
                        setFormData={setFormData}
                        formData={formData}
                        data={props}
                    />
                    <TabSoulMates
                        active={active === 2}
                        setActive={() => doSetActive(3)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabMetOnline
                        active={active === 3}
                        setActive={() => doSetActive(4)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabRelationship
                        active={active === 4}
                        setActive={() => doSetActive(5)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabRelationship2
                        active={active === 5}
                        setActive={() => doSetActive(6)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabPreferences
                        active={active === 6}
                        setActive={() => doSetActive(7)}
                        setFormData={setFormData}
                        formData={formData}
                        savePreferBodyType={savePreferBodyType}
                    />
                    <TabPreferences2
                        active={active === 7}
                        setActive={() => doSetActive(8)}
                        setFormData={setFormData}
                        formData={formData}
                        savePreferAge={savePreferAge}
                    />
                    <TabAge
                        active={active === 8}
                        setActive={() => doSetActive(9)}
                        setFormData={setFormData}
                        formData={formData}
                        setFormDataByMDY={setFormDataByMDY}
                    />
                    <TabName
                        active={active === 9}
                        setActive={() => doSetActive(10)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabEmail
                        active={active === 10}
                        setActive={() => doSetActive(11)}
                        setFormData={setFormData}
                        formData={formData}
                    />
                    <TabPassword
                        active={active === 11}
                        setActive={() => onSubmit()}
                        setFormData={setFormData}
                        formData={formData}
                        openTermModal={props?.openTermModal}
                    />
                </div>
                <div className={`${active > 1 ? 'gap' : ''}`}/>
            </div>
            <LoaderModal modalIsActive={modalIsActiveLoader}/>
        </>
    )
}