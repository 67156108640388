import ClickHistoryApi from "../../services/api/ClickHistoryApi";
import {getUrlParameter} from "../../services/methods";
import useDetectedDevice from "../device/useDetectedDevice";
import {isTrafficLandingName} from "../../services/methods/routes";

export default function useSaveTrafficConfig() {
    const {fullInfo} = useDetectedDevice();

    return (trafficConfig, age, callback) => {
        if (isTrafficLandingName() && (age >= trafficConfig?.age_from && age < trafficConfig?.age_to)) {
            try {
                new ClickHistoryApi()
                    .saveLndRedirect({
                        click_id: getUrlParameter('click_id'),
                        tail: window?.location?.search,
                        device: fullInfo(),
                        fingerPrint: trafficConfig?.fingerPrint,
                        redirect_url: trafficConfig?.redirect_url
                    })
                    .finally(() => window.location = trafficConfig?.redirect_url);
            } catch (e) {
                window.location = trafficConfig?.redirect_url;
                console.warn(e);
            }
        } else {
            if (callback) callback();
        }
    }
}