import React from "react";
import useTabName from "../../../hooks/lnd16/useTabName";
import styles from '../../../styles/lnd16v2/index.module.scss';

export default ({active=false, setActive, formData, setFormData}) => {
    const {inputValid, handleNext} = useTabName(formData, () => setActive());

    return (
        <>
            <div className={`${styles.box} ${active ? styles.active : styles.box_hidden}`}>
                <div className={`${styles['banner-box__cap']} ${styles['banner-box__cap_grid']}`}>
                    <div className={styles['title-site_h2-optional']}>Registration</div>
                    <ul className={`${styles['tabs']} ${styles['tabs-value']}`}>
                        <li className={styles['passed']}>1</li>
                        <li className={styles['passed']}>2</li>
                        <li className={styles['passed']}>3</li>
                        <li className={styles['passed']}>4</li>
                        <li className={styles['passed']}>5</li>
                        <li className={styles['current']}>6</li>
                        <li>7</li>
                        <li>8</li>
                    </ul>
                </div>

                <div className={`${styles['banner-box__body']} ${styles['banner-box__body_wrapp1']}`}>
                    <div className={styles['name_gap']}/>
                    <p className={styles['box__txt-st']}>Type in your name</p>
                    <div className={`${styles['form-group']} ${styles['form-group_sm']}`}>
                        <input className={`${styles['form-group__field']} ${styles['form-group-name__field']} ${styles['validate']}`}
                               type="text" placeholder="Enter your name"
                               onKeyUp={(e) => {
                                   if (e.key === 'Enter') {
                                       handleNext();
                                   }
                               }}
                               onChange={(e) => setFormData({...formData, ...{user_real_name: e?.target?.value}})} />
                    </div>
                    <div className={`${styles['input-label__suggestion']} ${styles['error_color']} ${styles['margin-top__10px']} ${!inputValid ? styles.error_color : styles.box_hidden}`}>Name cannot be empty</div>
                    <div className={styles['link-next']}>
                        <button type="button"
                                className={`${styles['btn']} ${styles['btn_bold']} ${styles['btn_lg']} ${styles['btn_min-width__no-margin']} ${styles['tabs-next']}`}
                                onClick={handleNext}>NEXT</button>
                    </div>
                </div>
            </div>
        </>
    )
}