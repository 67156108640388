import * as UTM_TAGS from '../../constants/utmTags';
import axios from 'axios';
import * as SOCIAL from '../../constants/social';
import * as DATE from '../../constants/date';
import * as LENGTH from '../../constants/length';

export function parseUrlParameter() {
    let click_id = '';
    const utm_netw = getUrlParameter(UTM_TAGS.NETW);
    const clickid = getUrlParameter(UTM_TAGS.CLICKID);
    const tail = window.location.search;

    if (utm_netw === 'MGID') click_id = getUrlParameter(UTM_TAGS.TID);
    else click_id = getUrlParameter(UTM_TAGS.MSCLKID);

    return {clickid, click_id, tail}
}

export function getTail() {
    return window.location.search ? window.location.search : '';
}

export function getUrlParameter(sParam) {
    let sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
}

export function checkDate(year, month, day) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dob = new Date(year, month - 1, day);
    const dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
    let age;

    age = today.getFullYear() - dob.getFullYear();

    if (today < dobnow) {
        age = age - 1;
    }

    return +age >= DATE.MIN_AGE;
}

export function getArrayOfNumbers(start, stop) {
    const result = [];
    while (start <= stop) {
        result.push(start++);
    }
    return result;
}

export function getArrayOfNumbersYear(start, stop) {
    const result = [];
    while (stop >= start) {
        result.push(stop--);
    }
    return result;
}

export function setLocalStorage(key, value) {
    if (localStorage.setItem(key, value)) return value;
    return '';
}

export function getLocalStorage(key) {
    return localStorage.getItem(key) || '';
}

export function injectParam(allParams, newParam, index) {
    if (allParams.length === 0)
        return newParam;

    let queryParams = allParams;
    if (allParams.startsWith('?'))
        queryParams = allParams.substring(1);

    if (includesParam(queryParams, newParam)) {
        queryParams = removeParam(queryParams, newParam);
    }

    const params = queryParams.split('&');
    if (params.length > index) {
        params.splice(index, 0, newParam);
    } else {
        params.push(newParam);
    }

    return params.join('&');
}

export function removeParam(allParams, toRemove) {
    if (allParams.length === 0)
        return allParams;

    let queryParams = allParams;
    if (allParams.startsWith('?'))
        queryParams = allParams.substring(1);

    const params = queryParams.split('&');
    const attributeName = toRemove.split('=')[0] + '=';

    return params.filter(p => {
        return !p.startsWith(attributeName)
    }).join('&');
}


function includesParam(allParams, newParam) {
    let paramKey = newParam;

    if (newParam.includes('=')) {
        paramKey = newParam.split('=')[0] + '=';
    }

    return allParams.includes(paramKey);
}

export function checkEmail(email = null) {
    if (email === null || +email?.length > LENGTH.MAX_EMAIL_LENGTH) return false;
    // eslint-disable-next-line
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const cyrillicPattern = /[\u0400-\u04FF]/;

    return (!cyrillicPattern.test(email) && pattern.test(email));
}

export function CheckEmailAPI(email) {
    return axios.post(`${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/api/login/checkEmail`, {
        email: email
    }).then(res => res.data).catch(e => console.warn(e));
}