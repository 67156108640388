import React from 'react';
import img from "../../../img/lnd16";
import styles from '../../../styles/lnd16/index.module.scss';

export default () => {
    return (
        <div
            className={`${window?.location?.pathname?.includes('block1') ? styles.container : styles['block-hidden']}`}>
            <div className={styles['main-cards-grid']}>
                <div className={`${styles['main-cards-grid__item']} ${styles['show-animation']}`}>
                    <div className={styles.card}>
                        <div className={styles.card__icon}>
                            <img src={img.images['card1.svg']} alt="icon"/>
                        </div>
                        <div className={styles.card__info}>
                            <div className={styles.card__tt}>Tons of free visual content</div>
                            <div className={styles.card__txt}>The variety of free photo galleries for every profile
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['main-cards-grid__item']} ${styles['show-animation']}`}>
                    <div className={styles.card}>
                        <div className={styles.card__icon}><img src={img.images['card2.svg']} alt="icon"/></div>
                        <div className={styles.card__info}>
                            <div className={styles.card__tt}>Free video shows</div>
                            <div className={styles.card__txt}>
                                This service will help you to see your interlocutor in real life as if no distance was
                                separating you both
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['main-cards-grid__item']} ${styles['show-animation']}`}>
                    <div className={styles.card}>
                        <div className={styles.card__icon}><img src={img.images['card3.svg']} alt="icon"/></div>
                        <div className={styles.card__info}>
                            <div className={styles.card__tt}>Passion for communication</div>
                            <div className={styles.card__txt}>All participants are longing for lasting communication
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['main-cards-grid__item']} ${styles['show-animation']}`}>
                    <div className={styles.card}>
                        <div className={styles.card__icon}><img src={img.images['card4.svg']} alt="icon"/></div>
                        <div className={styles.card__info}>
                            <div className={styles.card__tt}>Manually verified profiles</div>
                            <div className={styles.card__txt}>All members' profiles are manually verified by our moderation
                                team
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['main-cards-grid__item']} ${styles['show-animation']}`}>
                    <div className={styles.card}>
                        <div className={styles.card__icon}><img src={img.images['card5.svg']} alt="icon"/></div>
                        <div className={styles.card__info}>
                            <div className={styles.card__tt}>Safe payments</div>
                            <div className={styles.card__txt}>Maximal safety and privacy during communication and payments
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['main-cards-grid__item']} ${styles['show-animation']}`}>
                    <div className={styles.card}>
                        <div className={styles.card__icon}><img src={img.images['card6.svg']} alt="icon"/></div>
                        <div className={styles.card__info}>
                            <div className={styles.card__tt}>24/7 support</div>
                            <div className={styles.card__txt}>Our support team is always at your disposal 24/7 to help you with
                                any issues
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['main-cards-grid__item']} ${styles['show-animation']}`}>
                    <div className={styles.card}>
                        <div className={styles.card__icon}><img src={img.images['card7.svg']} alt="icon"/></div>
                        <div className={styles.card__info}>
                            <div className={styles.card__tt}>Variety of convenient tools</div>
                            <div className={styles.card__txt}>You can use chats, letters and likes to search and communicate
                                with your interlocutor
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['main-cards-grid__item']} ${styles['show-animation']}`}>
                    <div className={styles.card}>
                        <div className={styles.card__icon}><img src={img.images['card8.svg']} alt="icon"/></div>
                        <div className={styles.card__info}>
                            <div className={styles.card__tt}>Free registration</div>
                            <div className={styles.card__txt}>We provide free registration and transparent fees</div>
                        </div>
                    </div>
                </div>
                <div className={`${styles['main-cards-grid__item']} ${styles['show-animation']}`}>
                    <div className={styles.card}>
                        <div className={styles.card__icon}><img src={img.images['card9.svg']} alt="icon"/></div>
                        <div className={styles.card__info}>
                            <div className={styles.card__tt}>Constant access to correspondence</div>
                            <div className={styles.card__txt}>Your correspondence always stays yours. Chats and letters are
                                stored forever
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}