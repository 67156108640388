import React from 'react';
import ComponentLnd20Profile from '../../components/lnd20Profile';
import img from '../../img/lnd20Profile1';
import * as REDIRECT from '../../constants/redirect';

export default () => {
    return (
        <ComponentLnd20Profile
            backgroundImage={img.images['right_bg.jpg']}
            images={img.images}
            profileName={'Angelika'}
            profileDescription={'Sweet, romantic, tender, caring and open-hearted, I want to find a worthy man to open my soul to. I am passionate about traveling, art, outdoor activities, blogging and playing chess! Would you like to play chess with me?'}
            redirectLink={REDIRECT.SOFIA_LND20P1}
        />
    );
};