import {useState} from 'react';

export default function useTabPreferences2(setActive, savePreferAge) {
    const [checked, setChecked] = useState('18_100');

    const doCheck = (ageRange) => {
        if (checked !== ageRange) setChecked(ageRange);
    };

    const doOnNext = () => {
        savePreferAge(checked);
        setActive();
    }

    return{doCheck, doOnNext}
}