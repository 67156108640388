export const CURRENT_YEAR = new Date()?.getFullYear();
export const MIN_AGE = 18;
export const MAX_AGE = 99;

export const FORM_SELECT_DAY = 'Day';
export const FORM_SELECT_MONTH = 'Month';
export const FORM_SELECT_YEAR = 'Year';

export const ARRAY_MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
export const START_DAY = 1;
export const END_DAY = 31;
export const START_YEAR = 1920;
export const END_YEAR = new Date().getFullYear() - 18;