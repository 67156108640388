import {useEffect} from "react";
import axios from "axios";
import { getUrlParameter } from "../services/methods";
import * as SOCIAL from '../constants/social';

export default function useCheckValidToken() {
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));

        if (!!token) {
            axios
                .get(`${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/api/user/info`, {headers: {'Authorization': `Bearer ${token}`}})
                .then(res => res?.data)
                .then(res => {
                    if (res?.status) {
                        const redirectURLList = ['chat', 'search', 'letter', 'likeher', 'videoshow'];
                        const redirectURL = getUrlParameter('redirect_url');
                        const queryURL = `${window.location.search}${window.location.search.includes('?') ? '&callback=' : '?callback='}` + btoa(token);
                        
                        if (!redirectURL) window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/search${queryURL}`;
                        else if (!isNaN(redirectURL)) window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/profile-woman/${redirectURL}${queryURL}`;
                        else if (redirectURLList.includes(redirectURL.toLowerCase())) window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/${redirectURL.toLowerCase()}${queryURL}`;
                        else window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/search${queryURL}`;
                    }
                })
        }
    }, []);
}