import React from 'react';

import styles from '../styles/index.module.css';

import * as IMAGES from '../img';
import PrivacyPolicy from './PrivacyPolicy';
import CookiePolicy from './CookiePolicy';
import TermsAndConditions from './TermsAndConditions';

export default ({ pageName = null, close }) => {
    return (
        (pageName)
            ? <div className={styles.term_popup_wrap}>
                <div className={styles.term_popup_close}>
                    <img src={IMAGES.close} alt="" onClick={close} />
                </div>
                <div className={styles.term_popup_title}>{pageName}</div>
                {/* <div className={styles.term_popup_content} dangerouslySetInnerHTML={{__html: page}}/> */}
                <div className={styles.term_popup_content}>
                    {pageName === 'Cookie Policy' && <CookiePolicy />}
                    {pageName === 'Privacy Policy' && <PrivacyPolicy />}
                    {pageName === 'Terms and Conditions' && <TermsAndConditions />}
                </div>
            </div>
            : <img src={IMAGES.loading} alt={'Loading'} className="loader" />

    )
}