import React from "react";
import {Helmet} from "react-helmet";
// import SITE from "../../../config/site";

export default () => {
    return(
        <Helmet>
            {/*<title>{SITE.title}</title>*/}
        </Helmet>
    )
}
