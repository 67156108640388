import {useState} from "react";

export default function useTabPassword(formData, setActive, setFormData, isAgree, setIsAgree) {
    const [passValid, setPassValid] = useState(true);
    const [warningText, setWarningText] = useState('');

    const handleNext = () => {
        if (formData?.password?.length < 6) {
            setPassValid(false);
            return false;
            // eslint-disable-next-line no-useless-escape
        }
        setActive();
    };

    //for lnd16v2
    const toggleCheckBox = () => {
        setIsAgree(!isAgree);
        setPassValid(true);
        setWarningText('');
    }

    const handleUserPassword = (value) => {
        setFormData({...formData, ...{password: value}});
        setPassValid(true);
        setWarningText('');
    }

    const onSubmit = () => {
        if (!formData?.password?.length || !isAgree) {
            setWarningText('All fields are required');
            setPassValid(false);
            return false;
        } else if (formData?.password?.length < 6) {
            setWarningText('Minimum 6 characters');
            setPassValid(false);
            return false;
        }

        setActive();
    }

    return {handleNext, passValid, onSubmit, warningText, toggleCheckBox, handleUserPassword}
}