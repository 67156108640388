import React, {useState} from "react";
import useTabPassword from "../../../hooks/lnd16/useTabPassword";
import styles from '../../../styles/lnd16v2/index.module.scss';

export default ({active = false, setActive, formData, setFormData, openTermModal}) => {
    const [isAgree, setIsAgree] = useState(false);

    const {handleNext, passValid, onSubmit, warningText, toggleCheckBox, handleUserPassword} = useTabPassword(formData, setActive, setFormData, isAgree, setIsAgree);

    return (
        <>
            <div className={`${styles.box} ${active ? styles.active : styles.box_hidden}`}>
                <div className={`${styles['banner-box__cap']} ${styles['banner-box__cap_grid']}`}>
                    <div className={styles['title-site_h2-optional']}>Final step</div>
                    <ul className={`${styles['tabs']} ${styles['tabs-value']}`}>
                        <li className={styles.passed}>1</li>
                        <li className={styles.passed}>2</li>
                        <li className={styles.passed}>3</li>
                        <li className={styles.passed}>4</li>
                        <li className={styles.passed}>5</li>
                        <li className={styles.passed}>6</li>
                        <li className={styles.passed}>7</li>
                        <li className={styles.current}>8</li>
                    </ul>
                </div>

                <div className={`${styles['banner-box__body']} ${styles['banner-box__body_wrapp1']}`}>
                    <div className={`${styles['form-group']} ${styles['form-group_sm']}`}>
                        <p className={styles['box__txt-st']}>Enter your password</p>
                        <input id="password"
                               className={`${styles['form-group__field']} ${styles['form-group-name__field']} ${styles['validate']}`}
                               type="text"
                               placeholder="Your password"
                               value={formData?.password}
                               onKeyUp={(e) => {
                                   if (e.key === 'Enter') {
                                       handleNext();
                                   }
                               }}
                               onChange={(e) =>  handleUserPassword(e?.target?.value)}
                        />
                    </div>
                    <div className={`${styles['input-label__suggestion']} ${passValid ? '' : styles.error_color}`}>{warningText}</div>
                    <div className={styles.pass_policy_accept_wrap}>
                        <div className={styles.pass_policy_accept_input}>
                            <input type="checkbox"
                                   name="policy_accept"
                                   id="policy_accept_1"
                                   checked={isAgree}
                                   onChange={() => console.log(isAgree)}
                            />
                            <label htmlFor="policy_accept_1" onClick={toggleCheckBox}/>
                        </div>
                        <div className={styles.pass_policy_accept_text}>
                            By clicking “Find Your Matches” you agree with the&nbsp;
                            <span onClick={() => openTermModal('Terms and Conditions')}>Terms and Conditions</span>,&nbsp;
                            <span onClick={() => openTermModal('Privacy Policy')}>Privacy Policy</span>&nbsp;
                            and <span onClick={() => openTermModal('Cookie Policy')}>Cookies Policy</span>
                        </div>
                    </div>
                    <div className={styles['link-next']}>
                        <button type="button" className={`${styles['btn']} ${styles['btn_bold']} ${styles['btn_lg']} ${styles['btn_min-width__no-margin']} ${styles['tabs-next']}`}
                                onClick={onSubmit}>Find your matches
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}