import React, {useState} from 'react';
import styles from './styles.module.scss';
import img from '../../img/lnd20Profile';

export default ({profileName, profileDescription, images, redirectLink, backgroundImage}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    const imgArray = [
        "pb1.jpg",
        "pb2.jpg",
        "pb3.jpg",
        "pb4.jpg",
        "pb5.jpg",
    ]

    const openModal = (idx) => {
        setCurrentSlide(idx)
        setIsModalOpen(true)
    }
    const closeModal = () => {
        setIsModalOpen(false)
    }

    const setNextSlide = () => {
        currentSlide === imgArray.length - 1 ? setCurrentSlide(0) : setCurrentSlide(currentSlide + 1)
    }
    const setPrevSlide = () => {
        currentSlide === 0 ? setCurrentSlide(imgArray.length - 1) : setCurrentSlide(currentSlide - 1)
    }

    const redirectToLnd = () => {
        const params = window?.location?.search;
        window.location = `${redirectLink}${params}`;
    }

    return (
        <div className={styles.profile_page_fixed}>
            <div className={styles.profile_page_fixed_wrap}>
                <div className={styles.profile_page_left}>
                    <div className={styles.profile_page_name}>{profileName}</div>
                    <div className={styles.profile_page_content}>
                        <div className={styles.profile_page_title}>Hi there, <span>let’s talk!</span></div>
                        <div className={styles.profile_page_text}>
                            <p>I’m from Ukraine and I believe love have no borders.</p>
                            <p>{profileDescription}</p>
                            <p><span>I’m looking someone could make my life unusual!</span></p>
                        </div>
                        <div className={styles.profile_page_get_btn} onClick={redirectToLnd}>
                            <img src={img.images['get_btn_ico.svg']} alt=""/>
                            <span>Get acquainted </span>
                        </div>
                    </div>
                    <div className={styles.profile_page_photos_wrap}>
                        <div className={styles.profile_page_photos_text}>
                            <div className={styles.profile_page_photos_title}>My photos</div>
                            <div className={styles.profile_page_photos_all_link} onClick={() => openModal(0)}>View all my pics</div>
                        </div>
                        <div className={styles.profile_page_photos_list}>
                            {
                                imgArray.map((item, index) => {
                                    return <img src={images[imgArray[index]]} onClick={() => openModal(index)} alt=""/>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.profile_page_right} style={{backgroundImage: `url(${backgroundImage})`}}/>
            </div>
            {
                isModalOpen &&
                <div className={styles.profile_page_modal}>
                    <img src={images[imgArray[currentSlide]]} alt="" className={styles.profile_page_modal_image}/>
                    <div
                        className={`${styles.profile_page_modal_arrow} ${styles.prev}`}
                        onClick={setPrevSlide}
                    >
                        <img src={img.images['arrow_left.svg']} alt=""/>
                    </div>
                    <div
                        className={`${styles.profile_page_modal_arrow} ${styles.next}`}
                        onClick={setNextSlide}
                    >
                        <img src={img.images['arrow_right.svg']} alt=""/>
                    </div>
                    <div className={`${styles.profile_page_modal_close}`} onClick={closeModal}>
                        <img src={img.images['cross_white.svg']} alt=""/>
                    </div>
                </div>
            }
        </div>
    );
};
