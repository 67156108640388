import {useEffect, useState} from 'react';
import * as UTM_TAGS from "../../constants/utmTags";
import {getUrlParameter, injectParam} from "../../services/methods";
import * as SOCIAL from "../../constants/social";
import * as ROUTES from "../../constants/routes";
import LoginApi from "../../services/api/LoginApi";
import {useHistory, useLocation} from "react-router-dom";
import useInitLoader from "../useInitLoader";
import useGetLandingName from "../useGetLandingName";
import useSaveTrafficConfig from "../traffic/useSaveTrafficConfig";

export default function useInitBlockForm({initStateFormData, trafficConfig}) {
    const location = useLocation();
    const history = useHistory();
    const [active, setActive] = useState(1);
    const [formData, setFormData] = useState(initStateFormData);
    const {modalIsActiveLoader, setModalIsActiveLoader} = useInitLoader();
    const lndName = useGetLandingName();
    const saveTrafficConfig = useSaveTrafficConfig();

    const setGender = (key, value) => {
        const path = doSetActive(2);
        saveIndexedHistoryWithPath(path, key, value, 1);
    };

    const doSetActive = (num) => {
        const pathName = `${lndName?.length ? `/${lndName}` : ''}/block${num}`;

        history.push(pathName + window?.location?.search?.substring());
        setActive(num);

        return pathName;
    };

    function getPreviousBlock() {
        if (+active === 1) return 1;
        return +active - 1
    }

    const saveHistory = (key, value) => saveIndexedHistory(key, value, 1);

    const savePreferBodyType = (bodyTypeId) => {
        const path = doSetActive(active + 1);

        saveIndexedHistoryWithPath(path, UTM_TAGS.PREFER_BODY_TYPE, bodyTypeId, 2);
    }

    const savePreferAge = (rangePreferAge) => {
        const path = doSetActive(active + 1);

        saveIndexedHistoryWithPath(path, UTM_TAGS.PREFER_AGE, rangePreferAge, 3);
    }

    const saveAge = () => {
        saveTrafficConfig(trafficConfig, formData?.age, () => {
            const path = doSetActive(active + 1);
            saveIndexedHistoryWithPath(path, UTM_TAGS.REAL_AGE, formData?.age, 4);
        });
    };

    //for lnd16v2
    const setFormDataByMDY = (value, index) => {
        if (index === 'mm' || index === 'dd' || index === 'yyyy') {
            if (isNaN(+value)) return;
            setFormData({...formData, ...{[index]: +value}});
        }
    }

    const saveLndNameHistoryPath = () => {
        const path = doSetActive(active + 1);
        saveIndexedHistoryWithPath(path, UTM_TAGS.LND_NAME, lndName, 5);
    }

    const saveIndexedHistory = (key, value, index) => {
        saveIndexedHistoryWithPath(`${lndName?.length ? `/${lndName}` : ''}/block${active}`, key, value, index)
    };

    const saveIndexedHistoryWithPath = (path, key, value, index) => {
        const param = key + '=' + value;
        const params = injectParam(window?.location?.search?.substring(), param, index);

        history.push(path + '?' + params);
    };

    const onSubmit = () => {
        const now = new Date();
        const year = now?.getFullYear() - +formData?.age;
        setModalIsActiveLoader(true);
        saveLndNameHistoryPath();

        new LoginApi()
            .postRegister(
                formData?.email,
                formData?.password,
                1, 1, year, +formData?.gender,
                formData?.user_real_name,
                0, 18, 100, 0
            )
            .then(res => {
                if (res?.status && res?.token) {
                    const redirectURLList = ['chat', 'search', 'letter', 'likeher', 'videoshow'];
                    const redirectURL = getUrlParameter('redirect_url');
                    const queryURL = `${window.location.search}&registration=success&callback=` + btoa(res.token);

                    localStorage.setItem('token', JSON.stringify(res.token));

                    if (ROUTES.REDIRECT_POST_REGISTRATION.includes(lndName)) return window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/post-register${queryURL}`;

                    if (!redirectURL) window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/search${queryURL}`;
                    else if (!isNaN(redirectURL)) window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/profile-woman/${redirectURL}${queryURL}`;
                    else if (redirectURLList.includes(redirectURL.toLowerCase())) window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/${redirectURL.toLowerCase()}${queryURL}`;
                    else window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/search${queryURL}`;
                } else {
                    alert('Registration failed, please try again.');
                    window.location.href = `${lndName?.length ? `/${lndName}` : ''}`;
                }
            })
    };

    useEffect(() => {
        // if (window.location.pathname.includes('block')) {
        if (!window.location.search.includes(UTM_TAGS.UNIK)) {
            new LoginApi()
                .getCheckCountIp()
                .then(res => {
                    let unikParam = '';

                    if (res && +res.count === 0) unikParam = `${UTM_TAGS.UNIK}=true`;
                    else unikParam = `${UTM_TAGS.UNIK}=false`;

                    const utmProfile = `${UTM_TAGS.PROFILE}=${UTM_TAGS.NAME_PROFILE}`;
                    const redirectToProfile = `redirect_url=${UTM_TAGS.ID_PROFILE}`;

                    const params = injectParam(window.location.search.substring(1), `${unikParam}&${utmProfile}&${redirectToProfile}`, 0);

                    history.push(`${lndName?.length ? `/${lndName}` : ''}/block${active}?${params}`);
                })
        } else {
            doSetActive(1);
        }

        const params = window?.location?.search;
        localStorage.setItem('lnd_tail', params);

        if (window?.location?.search?.includes('email')) {
            setFormData(({...formData, ...{email: getUrlParameter('email')}}));
        }
        // }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const listener = ev => {
            ev.preventDefault();

            if (window.location.hash.length < 2) doSetActive(getPreviousBlock());
        };

        window.addEventListener('popstate', listener);

        return () => {
            window.removeEventListener('popstate', listener)
        }
    }, [location, active]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        formData, setFormData,
        active,
        setGender,
        doSetActive,
        getPreviousBlock,
        saveHistory,
        savePreferBodyType,
        savePreferAge,
        saveAge,
        setFormDataByMDY,
        saveIndexedHistory,
        saveIndexedHistoryWithPath,
        onSubmit,
        modalIsActiveLoader
    }
}
