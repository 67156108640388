import {useState} from 'react';
import * as UTM_TAGS from "../../constants/utmTags";

export default function useTabPreferences(setActive, savePreferBodyType) {
    const [checked, setChecked] = useState(UTM_TAGS.BODY_TYPE_NOT_SPECIFIED_ID);

    const doCheck = (key) => {
        if (+checked !== +key) setChecked(key);
    };

    const doOnNext = () => {
        savePreferBodyType(checked);
        setActive();
    }

    return{doCheck, doOnNext}
}