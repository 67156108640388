import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Helmet from '../common/Helmet';
import MainBlock from './MainBlock';

import useInitBlock from "../../hooks/lnd16/useInitBlock";
import useCheckValidToken from "../../hooks/useCheckValidToken";
import useGetTrafficConfig from "../../hooks/traffic/useGetTrafficConfig";
import "../../styles/lnd16v2/main.css";

export default (props) => {
    useCheckValidToken();
    useInitBlock();
    const getTrafficConfig = useGetTrafficConfig();

    return (
        <>
            <div id="wrapper">
                <Helmet/>
                <Header/>
                <MainBlock {...props} {...getTrafficConfig}/>
                <Footer/>
            </div>
        </>
    );
};
