import React from "react";
import * as REGISTRATION from "../../../constants/registration";
import styles from '../../../styles/lnd16/index.module.scss';

export default ({active = false, setActive, formData, setFormData, data}) => {
    const onSetGender = (value) => {
        setFormData({ ...formData, ...{ gender: value } });
    };

    return (
        <>
            <div className={`${styles.box} ${active ? styles.active : styles.box_hidden}`}>
                <div className={styles['banner-box__cap']}>
                    <div className={`${styles['title-site']} ${styles['title-site_h2-new']}`}>{data?.mainTitle}</div>
                </div>
                <div className={`${styles['banner-box__body']} ${styles['banner-box__body_wrapp1']} ${styles['banner-box__body_alone']}`}>
                    <div className={styles['box-preview']}>
                        <div className={styles['box-preview__cap']}>
                            <img src={data?.image} alt="image1"/>
                        </div>
                        <div className={styles['first-block__box-preview__desc']}>{data?.title}</div>
                        <div className={styles['first-block__box-preview__desc-text']}>{data?.description}</div>
                        <button type="button" className={`${styles.btn} ${styles.btn_lg} ${styles.btn_bold} ${styles['tabs-next-novalidate']} ${styles['first-block__box-preview__next_btn']}`}
                            onClick={() => {
                                onSetGender(REGISTRATION.GENDER_MALE);
                                setActive();
                            }}
                        >
                            {data?.btnTitle}
                        </button>
                        <div className={styles['first-block__box-preview__desc-free']}>{data?.subTitle}</div>
                        <div className={styles['first-block__box-preview__desc-no-reg']}>{data?.subDescription}</div>
                    </div>
                </div>
            </div>
        </>
    )
}