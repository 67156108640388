import {useState} from "react";

export default function useTabName(formData, setActive) {
    const [inputValid, setInputValid] = useState(true);

    const handleNext = () => {
        if (formData?.user_real_name?.trim() === '') {
            setInputValid(false);
            return false;
        }

        setActive();
    };

    return {inputValid, handleNext}
}