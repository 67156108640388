import React, {useRef, useState} from "react";
import useTabEmail from "../../../hooks/lnd16/useTabEmail";
import styles from '../../../styles/lnd16v2/index.module.scss';
import * as REGISTRATION from '../../../constants/registration';
import useOutsideClicker from "../../../hooks/useOutsideClicker";

export default ({active=false, setActive, formData, setFormData}) => {
    const {handleNext, inputValid, emailUnique, handleUserEmail} = useTabEmail(formData, setActive, setFormData);

    const [openBox, setOpenBox] = useState(false);

    const selectEmailRef = useRef(null);

    useOutsideClicker(selectEmailRef, setOpenBox);

    const trimEmail = formData?.email?.split('@')[0];

    const onSelectDomain = (e, item) => {
        e.stopPropagation();
        setFormData({...formData, ...{email: `${trimEmail}@${item}`}});
        setOpenBox(false);
    }

    return (
        <>
            <div className={`${styles.box} ${active ? styles.active : styles.box_hidden}`}>
                <div className={`${styles['banner-box__cap']} ${styles['banner-box__cap_grid']}`}>
                    <div className={styles['title-site_h2-optional']}>Registration</div>
                    <ul className={`${styles['tabs']} ${styles['tabs-value']}`}>
                        <li className={styles['passed']}>1</li>
                        <li className={styles['passed']}>2</li>
                        <li className={styles['passed']}>3</li>
                        <li className={styles['passed']}>4</li>
                        <li className={styles['passed']}>5</li>
                        <li className={styles['passed']}>6</li>
                        <li className={styles['current']}>7</li>
                        <li>8</li>
                    </ul>
                </div>
                <div className={`${styles['banner-box__body']} ${styles['banner-box__body_wrapp1']}`}>
                    <div className={styles['name_gap']}/>
                    <p className={styles['box__txt-st']}>Type in your email</p>
                    <div className={`${styles['form-group']} ${styles['form-group_sm']}`}>
                        <div className={`${styles.email_input_item} ${openBox ? styles.open : ''}`}
                             ref={selectEmailRef}
                             onClick={() => setOpenBox(true)}>
                            <input type="email"
                                   className={styles.email_input}
                                   placeholder="Enter your email"
                                   value={formData?.email ?? ''}
                                   onChange={(e) => handleUserEmail(e?.target?.value)}
                            />
                            <div className={styles.email_input_list_wrap}>
                                <div className={styles.email_input_list}>
                                    {
                                        REGISTRATION.DOMAIN_ARRAY.map((item, index) => (
                                            <div className={styles.email_input_list_item} key={`${index}_${item}`}
                                                 onClick={(e) => onSelectDomain(e, item)}>
                                                <input type="radio" id={`select_email_${index}`} name="select_email"/>
                                                <label htmlFor={`select_email_${index}`}>{trimEmail}@{item}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles['input-label__suggestion']} ${styles['error_color']} ${styles['margin-top__10px']} ${!inputValid ? styles['error_color'] : styles['box_hidden']}`}>Invalid email</div>
                    <div className={`${styles['input-label__suggestion']} ${styles['error_color']} ${styles['margin-top__10px']} ${!emailUnique ? styles['error_color'] : styles['box_hidden']}`}>This email is in use</div>
                    <div className={styles['link-next']}>
                        <button type="button" className={`${styles['btn']} ${styles['btn_bold']} ${styles['btn_lg']} ${styles['btn_min-width__no-margin']} ${styles['tabs-next']}`}
                                onClick={handleNext}>NEXT</button>
                    </div>
                </div>
            </div>
        </>
    )
}