import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import useGetLandingName from "../useGetLandingName";

export default function useInitBlock() {
    const history = useHistory();
    const lndName = useGetLandingName();

    useEffect(() => {
        if (!window?.location?.pathname?.includes('block') && lndName) {
            const params = window?.location?.search;
            history.push(`${lndName}/block1${params}`);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
}