import axios from "axios";
import * as SOCIAL from "../../constants/social";
import SITE from "../../config/site";

export default class ClickHistoryApi {
    getLndSettings(lnd) {
        return axios
            .post(`${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/api/v3/click-history/get-lnd-setting`, {lnd, site_id: SITE.ID})
            .then(res => res?.data)
            .catch((e) => {
                console.warn(e);
                return {status: false};
            })
    }

    saveLndRedirect({tail, click_id, fingerPrint, device, redirect_url}) {
        return axios
            .post(`${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/api/v3/click-history/save-lnd-redirect`, {
                tail,
                click_id,
                fingerPrint,
                device,
                redirect_url,
                site_id: SITE.ID
            })
            .then(res => res?.data)
            .catch((e) => {
                console.warn(e);
                return {status: false};
            })
    }
}