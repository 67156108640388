import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {getLandingName, getLandingPageComponent} from "../services/methods/routes";

export const Router = () => {
    return (
        <main className="content">
            <Switch>
                <Route exact path={`/${getLandingName()}`} component={getLandingPageComponent()}/>
                <Route exact path={`/${getLandingName()}/:block`} component={getLandingPageComponent()}/>
            </Switch>
        </main>
    )
};
