export default {
    sofiadate: {
        ID: 1,
        key: 'sofiadate',
        title: 'SofiaDate.com',
        brand: 'Sofia Date',
        copyrightsText: 'SofiaDate.com',
        brandUrl: 'sofia20',
    },
    myspecialdates:{
        ID: 2,
        key: 'myspecialdates',
        title: 'Myspecialdates.com',
        brand: 'My Special Dates',
        copyrightsText: 'Myspecialdates.com',
        brandUrl: 'myspecialdates',
    }
}[process.env.REACT_APP_SITE ?? 'sofiadate'];
