import {useState} from 'react';
import * as DATE from '../../constants/date';
import {getArrayOfNumbersYear} from "../../services/methods";
import moment from "moment";
import useAddTail from "../useAddTail";

export default function useTabAge(setActive, formData, setFormData) {
    const addTail = useAddTail();

    const [inputValid, setInputValid] = useState(true);

    const handleNext = () => {
        if (isValid(formData?.age)) {
            setActive();
        } else {
            setInputValid(false);
        }
    };

    const doSetFormData = (target) => {
        const value = target?.value;

        if (value?.length > 2) {
            target.value = value?.substring(0, 2);
        } else {
            setFormData({...formData, ...{age: value}});
        }
    };

    function isValid(age) {
        if (age?.length === 0) {
            return false;
        }

        const num = parseInt(age);
        return num >= DATE.MIN_AGE && num <= DATE.MAX_AGE
    }

    //for lnd16v2
    const setSelectedUserAgeByMDY = () => {
        if (+formData?.dd > 0 && +formData?.mm > 0 && +formData?.yyyy > 0) {
            const userBirthYear = (getArrayOfNumbersYear(DATE.START_YEAR, DATE.END_YEAR)).reverse()[+formData?.yyyy - 1];

            if (!(+formData?.mm > 0 && +formData?.mm <= 12) || !(+formData?.dd > 0 && +formData?.dd <= 31) || !(userBirthYear >= 1920 && userBirthYear <= DATE.CURRENT_YEAR)) {
                setInputValid(false);
                return false;
            }

            const age = moment().diff(`${userBirthYear}/${+formData?.mm}/${+formData?.dd}`, 'years');

            if (!age) {
                setInputValid(false);
                return false;
            } else if (+age < DATE.MIN_AGE) {
                setInputValid(false);
                return false;
            }

            setFormData({...formData, ...{age: age}});
            addTail('utm_real_age', age);
            setActive();
            return true;
        } else {
            setInputValid(false);
            return false;
        }
    };

    return {handleNext, doSetFormData, inputValid, setSelectedUserAgeByMDY, setInputValid}
}