export default {
    overlay: {
        left: '0',
        top: '0',
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(55,78,108,0.32)',
        zIndex: 99999,
        backdropFilter: 'blur(4px)',
    },
    content: {
        width: 'calc(100% - 30px)',
        height: 'calc(100% - 30px)',
        top: '15px',
        left: '15px',
        right: 0,
        bottom: 0,
    }
}
