import React from 'react';
import Modal from "react-modal";

import termStyle from "./styles/modal.styles";

import Terms from "./Terms";

export default ({modalIsActiveTerm, closeTermModal, termPageName}) => {
    return(
        <Modal
            style={termStyle}
            isOpen={modalIsActiveTerm}
            onRequestClose={closeTermModal}
            ariaHideApp={false}
        >
            <Terms
                pageName={termPageName}
                close={closeTermModal}
            />
        </Modal>
    )
}