import {useHistory, useLocation} from "react-router-dom";
import {getUrlParameter} from "../services/methods";

export default function useAddTail() {
    const currentLocation = useLocation();
    const history = useHistory();

    return (key, value) => {
        try {
            const param = key + '=' + value;
            let searchStr = window?.location?.search;

            if (searchStr?.includes(key)) searchStr = searchStr.replace(`${key}=${getUrlParameter(key)}`, `${key}=${value}`);
            else searchStr = searchStr?.length ? `${searchStr}&${param}` : `?${param}`;

            history.push(`${currentLocation?.pathname}${searchStr ?? ''}`);
        } catch (e) {
            console.warn(e);
        }
    }
}