import {useEffect, useState} from "react";
import {getLandingName, isTrafficLandingName} from "../../services/methods/routes";
import ClickHistoryApi from "../../services/api/ClickHistoryApi";

export default function useGetTrafficConfig() {
    const [trafficConfig, setTrafficConfig] = useState(null);

    const fingerPrintLSName = 'print';

    useEffect(() => {
        if (isTrafficLandingName()) {
            new ClickHistoryApi()
                .getLndSettings(getLandingName())
                .then(res => {
                    if (res?.status && res?.result && res?.fingerPrint) {
                        const fingerPrintLS = localStorage.getItem(fingerPrintLSName) ?? null;
                        const fingerPrint = localStorage.getItem(fingerPrintLSName) ?? res?.fingerPrint;

                        if (!fingerPrintLS) localStorage.setItem(fingerPrintLSName, fingerPrint);

                        setTrafficConfig({...res?.result, ...fingerPrint});
                    }
                });
        }
    }, []);

    return {trafficConfig}
}