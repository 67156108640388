import React from "react";
import SmallSelect from "./SmallSelect";
import useTabAge from "../../../hooks/lnd16/useTabAge";
import styles from '../../../styles/lnd16v2/index.module.scss';
import * as DATE from '../../../constants/date';
import {getArrayOfNumbers, getArrayOfNumbersYear} from "../../../services/methods";


export default ({active = false, setActive, formData, setFormData, setFormDataByMDY}) => {
    const {inputValid, setSelectedUserAgeByMDY, setInputValid} = useTabAge(() => setActive(), formData, setFormData);

    return (
        <>
            <div className={`${styles.box} ${active ? styles.active : styles.box_hidden}`}>
                <div className={`${styles['banner-box__cap']} ${styles['banner-box__cap_grid']}`}>
                    <div className={styles['title-site_h2-optional']}>Registration</div>
                    <ul className={`${styles.tabs} ${styles['tabs-value']}`}>
                        <li className={styles.passed}>1</li>
                        <li className={styles.passed}>2</li>
                        <li className={styles.passed}>3</li>
                        <li className={styles.passed}>4</li>
                        <li className={styles.current}>5</li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                    </ul>
                </div>

                <div className={`${styles['banner-box__body']} ${styles['banner-box__body_wrapp1']}`}>
                    <div className={styles.age_gap}/>
                    <div
                        className={`${styles['form-group']} ${styles['form-group_sm-push']} ${styles['form-group_year']}`}>
                        <div className={styles['box-preview__desc']}>Understanding your real age will help to find the
                            best matches for you
                        </div>
                        <div className={styles.select_age_row}>
                            <SmallSelect
                                index="mm"
                                formData={formData?.mm}
                                defaultName={DATE.FORM_SELECT_MONTH}
                                arrayFields={DATE.ARRAY_MONTH}
                                handleClick={setFormDataByMDY}
                                setInputValid={setInputValid}
                            />
                            <SmallSelect
                                index="dd"
                                formData={formData?.dd}
                                defaultName={DATE.FORM_SELECT_DAY}
                                arrayFields={getArrayOfNumbers(DATE.START_DAY, DATE.END_DAY)}
                                handleClick={setFormDataByMDY}
                                setInputValid={setInputValid}
                            />
                            <SmallSelect
                                index="yyyy"
                                formData={formData?.yyyy}
                                defaultName={DATE.FORM_SELECT_YEAR}
                                arrayFields={(getArrayOfNumbersYear(DATE.START_YEAR, DATE.END_YEAR)).reverse()}
                                handleClick={setFormDataByMDY}
                                setInputValid={setInputValid}
                            />
                        </div>
                    </div>
                    <div
                        className={`${styles['input-label__suggestion']} ${styles.error_color} ${styles['margin-top__10px']} ${!inputValid ? styles.error_color : styles.box_hidden}`}>Your
                        age should be more than {DATE.MIN_AGE}
                    </div>
                    <div className={styles['link-next']}>
                        <button type="button"
                                className={`${styles['btn']} ${styles['btn_bold']} ${styles['btn_lg']} ${styles['btn_min-width']} ${styles['tabs-next']}`}
                                onClick={setSelectedUserAgeByMDY}>NEXT
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}