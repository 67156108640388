import React from "react";
import useTabPreferences from "../../../hooks/lnd16/useTabPreferences";
import styles from '../../../styles/lnd16/index.module.scss';
import * as UTM_TAGS from "../../../constants/utmTags";

export default ({active = false, setActive, savePreferBodyType}) => {
    const {doCheck, doOnNext} = useTabPreferences(() => setActive(), savePreferBodyType);

    return (
        <>
            <div className={`${styles.box} ${active ? styles.active : styles.box_hidden}`}>
                <div className={`${styles['banner-box__cap']} ${styles['banner-box__cap_grid']}`}>
                    <div className={`${styles['title-site_h2-optional']}`}>Questions</div>
                    <ul className={`${styles.tabs} ${styles['tabs-value']}`}>
                        <li className={styles.passed}>1</li>
                        <li className={styles.passed}>2</li>
                        <li className={styles.current}>3</li>
                        <li>4</li>
                        <li>5</li>
                        <li>6</li>
                        <li>7</li>
                        <li>8</li>
                    </ul>
                </div>

                <div className={`${styles['banner-box__body']} ${styles['banner-box__body_wrapp1']}`}>
                    <p className={styles['box__txt-st']}>What kind of body do you prefer?</p>
                    <div className={styles['flex-wrapper']}>
                    <ul className={`${styles['box__body-check']} ${styles['box__body-check_big']}`}>
                        <li>
                            <label className={styles['check']}>
                                <input
                                    className={`${styles['check__input']} ${styles['validate']}`}
                                    type="radio"
                                    name="body-type"
                                    onChange={() => doCheck(UTM_TAGS.BODY_TYPE_PETITE_ID)}
                                />
                                <span className={styles['check__box']}></span>
                                {UTM_TAGS.BODY_TYPE_PETITE}
                            </label>
                        </li>
                        <li>
                            <label className={styles['check']}>
                                <input
                                    className={`${styles['check__input']} ${styles['validate']}`}
                                    type="radio"
                                    name="body-type"
                                    onChange={() => doCheck(UTM_TAGS.BODY_TYPE_SLIM_ID)}
                                />
                                <span className={styles['check__box']}></span>
                                {UTM_TAGS.BODY_TYPE_SLIM}
                            </label>
                        </li>
                        <li>
                            <label className={styles['check']}>
                                <input
                                    className={`${styles['check__input']} ${styles['validate']}`}
                                    type="radio"
                                    name="body-type"
                                    onChange={() => doCheck(UTM_TAGS.BODY_TYPE_AVERAGE_ID)}
                                />
                                <span className={styles['check__box']}></span>
                                {UTM_TAGS.BODY_TYPE_AVERAGE}
                            </label>
                        </li>
                        <li>
                            <label className={styles['check']}>
                                <input
                                    className={`${styles['check__input']} ${styles['validate']}`}
                                    type="radio"
                                    name="body-type"
                                    onChange={() => doCheck(UTM_TAGS.BODY_TYPE_MUSCULAR_FIT_ID)}
                                />
                                <span className={styles['check__box']}></span>
                                {UTM_TAGS.BODY_TYPE_MUSCULAR_FIT}
                            </label>
                        </li>
                        <li>
                            <label className={styles['check']}>
                                <input
                                    className={`${styles['check__input']} ${styles['validate']}`}
                                    type="radio"
                                    name="body-type"
                                    onChange={() => doCheck(UTM_TAGS.BODY_TYPE_CURVY_ID)}
                                />
                                <span className={styles['check__box']}></span>
                                {UTM_TAGS.BODY_TYPE_CURVY}
                            </label>
                        </li>
                    </ul>
                    </div>
                    <div className={styles['link-next']}>
                        <button type="button" className={`${styles['btn']} ${styles['btn_bold']} ${styles['btn_lg']} ${styles['btn_min-width']} ${styles['tabs-next']}`}
                                onClick={() => doOnNext()}>NEXT
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}